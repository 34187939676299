const config  = {
	base_url: "http://bbiosur.com",
	base_dir: "/",
	db_host: "localhost",
	db_user: "biosur",
	db_pass: "kr.biosur123",
	db_name: "biosur"
};

/*const config  = {
	base_url: "http://kronos.lab",
	base_dir: "/biosur",
	db_host: "localhost",
	db_user: "root",
	db_pass: "",
	db_name: "biosur"
};*/

export default config;