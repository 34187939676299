Foundation.Abide.defaults.patterns['dashes_only'] = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
Foundation.Abide.defaults.patterns['name'] = /^[A-Za-zwáéíóäëiöúàèììù\s]+$/;
Foundation.Abide.defaults.patterns['todo'] = /.*/;

$(document).foundation();

//var $url = 'http://localhost:3000';
//Vue.root = "http://localhost:3000";

/*var $url = 'http://kronos.lab/muelas';
Vue.root = "http://kronos.lab/muelas";*/

import Vue from 'vue';
import VueResource from 'vue-resource';
import VueLocalStorage from 'vue-localstorage'
import _ from 'lodash';

import config from "../../config/app";

import Cotizar from './components/Cotizar.vue';
/*import Buscador from './components/Buscador.vue';
import Carro from './components/Carro.vue';
import AgregarCarro from './components/AgregarCarro.vue';
import CarroLista from './components/CarroLista.vue';
import ProductoAgregar from './components/ProductoAgregar.vue';*/

//var $url = 'http://kronos.lab';
var $url = `${config.base_url}${config.base_dir}`;
Vue.root = `${config.base_url}${config.base_dir}`;

//Vue.cotizacionUrl = Vue.root+"/cotizacion/enviar";
Vue.cotizacionUrl = "https://jsonplaceholder.typicode.com/posts";

Vue.use(VueResource);
Vue.use(VueLocalStorage);

Vue.http.options.xhr = { withCredentials : true };
Vue.http.options.emulateJSON = true;

Vue.carro = new Vue();

Vue.storage = new Vue({
	localStorage: {
		products: []
	}
})

var vm = new Vue({

	el: "body",
	components: {
		'kronos-cotizar': Cotizar,
		/*'kronos-buscador': Buscador,
		'kronos-carro': Carro,
		'kronos-agregar-carro': AgregarCarro,
		'kronos-carro-lista': CarroLista,
		'kronos-producto-agregar': ProductoAgregar,*/
	},
	data: {
		
	}

});

/*var tt = InView(document.querySelector('.header'), function(isInView, data) {
		//&& data.elementOffsetTopInViewHeight < 200
		if ($(window).width() > 1300) {
			if (!isInView) {
				$('.header2').fadeIn(250);
			} else {
				$('.header2').fadeOut(250);
			}
		} else {
			$('.header2').hide();
		}
	});*/

$(function(){

	var $slick = $('.slide__slick');
	$slick.slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		dots: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
	});

	var $tabs = $('.content__tabs__tab');
	var $modals = $('.content__modals__modal');

	$('.content__tabs__tab').on('click', function(event){
		event.preventDefault();
		$removeActives($tabs);
		$addActive($(this));
		$removeActives($modals);
		$addActive($modals.eq($(this).data('modal')));
	});

	var $removeActives = function(el){
		$.each(el, function(){
			$(this).removeClass('active');
		});
	};

	var $addActive = function(el){
		el.addClass('active');
	};

	/*$(window).on('resize', _.throttle(function(){
	}, 100));*/

	/*var $slick3 = $('.productos-slide__slick');
	$slick3.slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: true,
		dots: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
		slidesToShow: 4,
  		slidesToScroll: 1,
  		prevArrow:"<img class='a-left control-c prev slick-prev' src='img/larrow.jpg'>",
      	nextArrow:"<img class='a-right control-c next slick-next' src='img/rarrow.jpg'>",
      	responsive: [
      	{
      		breakpoint: 1300,
      		settings: {
      			slidesToShow: 3
      		}
      	},
      	{
      		breakpoint: 960,
      		settings: {
      			slidesToShow: 2
      		}
      	},
      	{
      		breakpoint: 620,
      		settings: {
      			slidesToShow: 1
      		}
      	},
      	]
	});*/

	/*var $map = new GMaps({
		div: '.map',
		lat: -37.350493,
		lng: -72.369791,
		zoom: 15,
		scrollwheel: false
	});

	$map.addMarker({
		lat: -37.350493,
		lng: -72.369791,
		title: 'QUALITATCORP',
		infoWindow: {
			content: '<h3>QUALITATCORP</h3>'
		}
	});*/

	/*var tt = InView(document.querySelector('.header'), function(isInView, data) {
		if (isInView) {
			$('.contactanos').stop().fadeOut('fast');
		} else {
			$('.contactanos').stop().fadeIn('fast').css('display','table');
		}
	});*/

	/*$('a[href*="#"]:not([href="#"])').click(function() {

		$('#offCanvas').foundation('close');

		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 700);
				return false;
			}
		}
		
	});

	$("#scroll-top").hide();
	// fade in #back-top
	$(window).scroll(function () {
		if ($(this).scrollTop() > 300) {
			$('#scroll-top').fadeIn();
		} else {
			$('#scroll-top').fadeOut();
		}
	});
	// scroll body to 0px on click
	$('#scroll-top button').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
	});*/

});